import React from 'react'
import { HeaderSizes } from '../../constants'
import Header from './../Header'
import ProgressBar from './../ProgressBar'
import ComponentError from '../States/ComponentError'
import FormActions from './FormActions'
import Card from '../Card'

const Form = ({
    children,
    className,
    submitCallback,
    secondaryActions,
    submitLabel,
    doubleActionsUI,
    submitProgress,
    submittingText,
    title,
}) => {
    const [loaded, setLoaded] = React.useState(false)
    const [submitting, setSubmitting] = React.useState(false)
    const submitButton = React.useRef()

    React.useEffect(() => {
        setTimeout(() => setLoaded(true), 1)
    }, [])

    const handleSubmit = async e => {
        e.preventDefault()
        setSubmitting(true)
        await submitCallback()
        setSubmitting(false)
    }

    const handleSubmitClick = () => {
        submitButton.current.click()
    }

    if (!submitCallback) {
        return (
            <ComponentError
                componentName='Form'
                errorMessage="A 'submitCallback' prop is needed"
            />
        )
    }
    return (
        <form
            className={`rds-form ${className || ''} ${
                loaded ? 'loaded' : 'unloaded'
            } ${submitting ? 'rds-form_submitting' : ''}`}
            onSubmit={handleSubmit}
        >
            {title ? <Header title={title} size={HeaderSizes.MEDIUM} /> : null}
            {doubleActionsUI ? (
                <FormActions
                    submitLabel={submitLabel}
                    submitCallback={handleSubmitClick}
                    secondaryActions={secondaryActions}
                />
            ) : null}
            {submitProgress !== null && submitProgress !== undefined ? (
                <Card className='rds-full-block rds-flex rds-align-center rds-justify-center'>
                    <ProgressBar
                        progress={submitProgress}
                        text={submittingText}
                        showPercentage
                    />
                </Card>
            ) : (
                <div
                    className={`rds-form_content rds-full-block rds-grid rds-grid_3-columns ${
                        doubleActionsUI ? 'rds-m_top__sm' : ''
                    }`}
                >
                    {children}
                </div>
            )}
            <FormActions
                isSubmitting={submitting}
                submitLabel={submitLabel}
                submitCallback={handleSubmitClick}
                secondaryActions={secondaryActions}
            />
            <input
                ref={submitButton}
                type='submit'
                style={{ display: 'none' }}
            />
        </form>
    )
}

export default Form
